import * as React from "react";
import styled from "styled-components";

class Card extends React.Component {
  public render() {
    return <CardContainer>{this.props.children}</CardContainer>;
  }
}

export default Card;

const CardContainer = styled.div`
  background-color: #f6f8fa;
  padding: 15px;
  margin-bottom: 10px;

  @media (max-width: 767px){
    padding: 10px;
  }
`;
