import * as React from 'react';
import styled from 'styled-components';

export default class Select extends React.Component<React.HTMLProps<HTMLSelectElement>, any> {
    public render() {
        return (
            <SelectContainer>
                <select  {...this.props}>
                    {this.props.children}
                </select>
            </SelectContainer>
        );
    }
}

const SelectContainer = styled.div`
select{
    font-size: 16px;
    border: 0;
    outline: none;
    border-bottom: 1px solid #02cc44;
    padding: 3px 0;
    width: 100%;
    padding-bottom: 4px;
}
`
