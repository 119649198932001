import React from "react";
import {ITeamMemberModel, IAchievementModel} from "./http";
import ListView from "./ListView";
import {achievementsGivenClient} from "./http/AchievementsGivenClient";
import AchievementForm from "./Forms/AchievementForm";
import Card from "./Card";
import * as moment from "moment/moment";
import {
  AchievedAt,
  Achievement,
  AchievementDescription,
  AchievementName,
  AwardedBy, AwardedTo,
  PointsEarned
} from "./AchievementStyles";

interface IAchievementsGivenProps {
  profile: ITeamMemberModel;
}

function renderAchievement(achievement: IAchievementModel) {
  return (
    <Card key={achievement.achievementId}>
      <Achievement>
        <AwardedTo>Awarded to: {achievement.teamMemberName}</AwardedTo>
        <AchievementName>{achievement.name}</AchievementName>
        <AchievementDescription>{achievement.description}</AchievementDescription>
        <PointsEarned>{achievement.pointsEarned} points</PointsEarned>
        <AchievedAt
          title={moment
            .utc(achievement.achievedAtUtc)
            .local()
            .format("MMMM Do YYYY, h:mm:ss a")}
        >
          {moment.utc(achievement.achievedAtUtc).fromNow()}
        </AchievedAt>
        {achievement.awardedByTeamMemberId && <AwardedBy>Awarded By: {achievement.awardedByTeamMemberName}</AwardedBy>}
      </Achievement>
    </Card>
  );
}

export const AchievementsGiven: React.FC<IAchievementsGivenProps> = (props: IAchievementsGivenProps) => {
  const { profile } = props;
   
  function renderAddForm(onItemSaved: () => void, onAddCancelled: () => void) {
    return <AchievementForm profile={profile} 
                            onSubmitted={onItemSaved} 
                            onCancelled={onAddCancelled}/>;
  }
  
  return (
    <ListView heading="Achievements given"
              loadItems={achievementsGivenClient.list}
              renderItem={renderAchievement}
              noItemsMessage="It doesn't look like you've given any good vibes. Change that today!"
              addForm={renderAddForm}
              addText="Award team member"
    />
  );
};