import * as React from 'react';
import styled from 'styled-components';

export default class PageHeader extends React.Component {
    public render() {
        return (
            <StyledPageHeader>
                {this.props.children}
            </StyledPageHeader>
        );
    }
}

const StyledPageHeader = styled.h2`
    margin-bottom: 10px;
`
