import * as React from 'react';
import PageHeader from '../PageHeader';
import ManageWelcomeMessage from './ManageWelcomeMessage';

export interface IManageConfigurationProps {
    foo: string
}

export default class ManageConfiguration extends React.Component<IManageConfigurationProps, any> {
    public render() {
        return (
            <React.Fragment>
                <PageHeader>Manage Configuration</PageHeader>
                <ManageWelcomeMessage />
            </React.Fragment>
        );
    }
}
