import * as moment from "moment";
import * as React from "react";
import Card from "./Card";
import { achievementsClient, IAchievementModel, ITeamMemberModel } from "./http";
import ListView from "./ListView";
import AchievementForm from "./Forms/AchievementForm";
import {
  AchievedAt,
  Achievement,
  AchievementDescription,
  AchievementName,
  AwardedBy,
  PointsEarned
} from './AchievementStyles';

interface IAchievementsProps {
  profile: ITeamMemberModel | undefined;
  reloadProfile: () => void;
}

interface IAchievementsState {
  achievements: IAchievementModel[] | null;
}

class Achievements extends React.Component<any, IAchievementsState> {
  public render() {
    return (
      <ListView
          heading="Your achievements"
          loadItems={this.getAchievements}
          renderItem={this.renderAchievement}
          noItemsMessage="We couldn't find any achievements! Try not to take it personally..."
          addForm={this.renderAddForm}
          addText="Award team member"
      />
    );
  }

  private getAchievements = () => {
    return achievementsClient.list();
  };

  private renderAchievement = (achievement: IAchievementModel) => {
    return (
      <Card key={achievement.achievementId}>
        <Achievement>
          <AchievementName>{achievement.name}</AchievementName>
          <AchievementDescription>{achievement.description}</AchievementDescription>
          <PointsEarned>{achievement.pointsEarned} points</PointsEarned>
          <AchievedAt
            title={moment
              .utc(achievement.achievedAtUtc)
              .local()
              .format("MMMM Do YYYY, h:mm:ss a")}
          >
            {moment.utc(achievement.achievedAtUtc).fromNow()}
          </AchievedAt>
          {achievement.awardedByTeamMemberId && <AwardedBy>Awarded By: {achievement.awardedByTeamMemberName}</AwardedBy>}
        </Achievement>
      </Card>
    );
  };

  private renderAddForm = (onItemSaved: () => void, onAddCancelled: () => void) => {
    const onSubmitted = () => {
      onItemSaved();
      this.props.reloadProfile();
    }
    return <AchievementForm profile={this.props.profile} onCancelled={onAddCancelled} onSubmitted={onSubmitted} />;
  };
}

export default Achievements;


