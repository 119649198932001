import styled from "styled-components";

export const Achievement = styled.div`
  display: grid;
  grid-template: auto auto auto / min-content auto 1fr;
`;

export const AwardedTo = styled.span`
  grid-column: 1 / 4;
  grid-row: 0;  
`;

export const AchievementName = styled.h3`
  grid-column: 1;
  grid-row: 1;
  white-space: nowrap;
`;

export const AchievementDescription = styled.div`
  grid-column: 1 / 4;
  grid-row: 2;
  margin-top: 5px;
`;

export const PointsEarned = styled.div`
  align-self: end;
  grid-column: 2;
  grid-row: 1;
  margin-left: 10px;
`;

export const AchievedAt = styled.small`
  justify-self: end;
  align-self: center;
  grid-column: 3;
  grid-row: 1;
  white-space: nowrap;
`;

export const AwardedBy = styled.span`
  grid-column: 1 / 4;
  grid-row: 3;  
`;