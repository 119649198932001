import * as React from "react";
import styled from "styled-components";
import Card from "../Card";
import Gravatar from "../Gravatar";
import ListView from "../ListView";
import { teamMembersClient, ITeamMemberModel } from "../http";
import TeamMemberForm from "./TeamMemberForm";
import EditTeamMemberForm from "./EditTeamMemberForm";
import DeleteTeamMemberForm from "./DeleteTeamMemberForm";
import ButtonToolbar from "../ButtonToolbar";
import Button from "../Button";

export default class ManageTeamMembers extends React.Component {
  public render() {
    return (
      <ListView
        heading="Manage Team Members"
        loadItems={this.getTeamMembers}
        renderItem={this.renderTeamMember}
        noItemsMessage="It doesn't look like there are any team members. Weird."
        addForm={this.renderAddForm}
        addText="Add Team Member"
        editForm={this.renderEditForm}
        editText="Edit"
        deleteForm={this.renderDeleteFrom}
        deleteText="Delete"
      />
    );
  }

  private getTeamMembers = () => {
    return teamMembersClient.list();
  };

  private renderTeamMember = (teamMember: ITeamMemberModel, refreshItems: () => void, editItem: (teamMember: ITeamMemberModel) => void, deleteItem: (teamMember: ITeamMemberModel) => void) => {
    return (
      <Card key={teamMember.teamMemberId}>
        <TeamMember>
          <Name>{teamMember.name}</Name>
          <Email>{teamMember.email}</Email>
          <Avatar>
            <Gravatar email={teamMember.email!} size={60} />
          </Avatar>
          <AvailablePoints>
            <span>Points to spend</span>
            <strong>{teamMember.availablePoints}</strong>
          </AvailablePoints>
          <AvailablePointsToAllocate>
            <span>Points to allocate</span>
            <strong>{teamMember.availablePointsToAllocate}</strong>
          </AvailablePointsToAllocate>
        </TeamMember>
        { !teamMember.isDeleted && (<Actions>
          <ButtonToolbar>
            <Button onClick={editItem} variant="secondary">
              Edit
            </Button>
            <Button onClick={deleteItem} variant="secondary">
              Delete
            </Button>
          </ButtonToolbar>
        </Actions>)
        }
      </Card>
    );
  };

  private renderAddForm = (onItemAdded: () => void, onAddCancelled: () => void) => {
    return <TeamMemberForm onCancelled={onAddCancelled} onSubmitted={onItemAdded} />;
  };

  private renderEditForm = (item: ITeamMemberModel, onItemSaved: () => void, onEditCancelled: () => void) => {
    return <EditTeamMemberForm initialState={item} onCancelled={onEditCancelled} onSubmitted={onItemSaved} />;
  };

  private renderDeleteFrom = (item: ITeamMemberModel, onItemSaved: () => void, onDeleteCancelled: () => void) => {
    return <DeleteTeamMemberForm initialState={item} onCancelled={onDeleteCancelled} onSubmitted={onItemSaved} />;
  };
}

const TeamMember = styled.div`
  display: grid;
  grid-template-rows: min-content 1fr auto auto;
  grid-template-columns: auto 1fr;
`;

const Name = styled.div`
  grid-row: 1;
  grid-column: 2;
`;

const Email = styled.small`
  grid-row: 2;
  grid-column: 2;
`;

const Avatar = styled.div`
  grid-row: 1/3;
  grid-column: 1;
  margin-right: 10px;
`;

const AvailablePoints = styled.div`
  grid-row: 3;
  grid-column: 1/3;
  span {
    margin-right: 5px;
  }
`;

const AvailablePointsToAllocate = styled.div`
  grid-row: 4;
  grid-column: 1/3;
  span {
    margin-right: 5px;
  }
`;

const Actions = styled.div`
  margin-top: 10px;
  grid-column: 1/4;
  grid-row: 5;
`;
