import * as React from "react";
import styled from "styled-components";
import ListView from "./ListView";
import Loading from "./Loading";
import { LoadingTracker } from "./LoadingTracker";
import RewardItem from "./RewardItem";
import {configurationClient, rewardsClient, IRewardModel, ITeamMemberModel} from "./http";
import {PointsAvailableMessage} from "./components/PointsAvailableMessage";

interface IHomeProps {
  reloadProfile: () => void;
  profile: ITeamMemberModel;
}

interface IHomeState {
  welcomeMessage: string;
}

class Home extends React.Component<IHomeProps, IHomeState> {
  private tracker: LoadingTracker;
  constructor(props: IHomeProps) {
    super(props);

    this.tracker = new LoadingTracker();

    this.state = {
      welcomeMessage: ""
    };

    this.tracker.track(
      configurationClient.get().then(response => {
        this.setState({ welcomeMessage: response.welcomeMessage! });
      })
    );
  }

  public render() {
    return (
      <Loading tracker={this.tracker}>
        <WelcomeMessage>{this.state.welcomeMessage}</WelcomeMessage>
        <br/>
        <PointsAvailableMessage profile={this.props.profile}/>
        <br/>
        <ListView loadItems={this.loadRewards} renderItem={this.renderReward} noItemsMessage="It doesn't look like there are any rewards available at the moment." />
      </Loading>
    );
  }

  private loadRewards = () => {
    return rewardsClient.list();
  };

  private renderReward = (reward: IRewardModel) => {
    return <RewardItem key={reward.rewardId} reward={reward} availablePoints={this.props.profile ? this.props.profile.availablePoints : 0} onRewardPurchased={this.onRewardPurchased} />;
  };

  private onRewardPurchased = () => {
    this.props.reloadProfile();
  };
}

export default Home;

const WelcomeMessage = styled.pre`
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
  padding: 10px 0;
`;
