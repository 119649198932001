import * as React from 'react';
import styled from 'styled-components';

export default class ButtonToolbar extends React.Component {
    public render() {
        return (
            <StyledButtonToolbar>
                {this.props.children}
            </StyledButtonToolbar>
        );
    }
}

const StyledButtonToolbar = styled.div`
> *:not(:first-child){
    margin-left: 10px;
}
`