import AppSettings from "AppSettings";
import { RewardsClient, AchievementsClient, PurchasesClient, TeamMemberClient, ConfigurationClient, ActiveDirectoryUsersClient } from "./api";
import axios from "axios";
import { getToken } from "ActiveDirectory";

const axiosInstance = axios.create({ baseURL: AppSettings.apiUrl });

axiosInstance.defaults.headers.common.Authorization = "Bearer " + getToken();

export const axiosClient = axiosInstance;

export const rewardsClient = new RewardsClient(AppSettings.apiUrl, axiosInstance);

export const achievementsClient = new AchievementsClient(AppSettings.apiUrl, axiosInstance);

export const purchasesClient = new PurchasesClient(AppSettings.apiUrl, axiosInstance);

export const teamMembersClient = new TeamMemberClient(AppSettings.apiUrl, axiosInstance);

export const configurationClient = new ConfigurationClient(AppSettings.apiUrl, axiosInstance);

export const activeDirectoryUsersClient = new ActiveDirectoryUsersClient(AppSettings.apiUrl, axiosInstance);