interface IRegistration{
  id: string,
  onBusyCallback: () => void;
  onCompleteCallback: () => void;
}

export class LoadingTracker {
  public isComplete: boolean;

  private activePromises: number;
  private registrations: IRegistration[];

  constructor() {
    this.activePromises = 0;
    this.isComplete = true;

    this.registrations = new Array<IRegistration>();
  }

  public track = (promise: Promise<any>) => {
    this.activePromises++;

    if (this.activePromises === 1) {
      this.registrations.forEach(registration =>{
        registration.onBusyCallback();
      })
    }

    promise
      .then(() => {
        this.handlePromiseComplete();
      })
      .catch(() => {
        this.handlePromiseComplete();
      });
  };

  public register(id: string, onBusyCallback: () => void, onCompleteCallback: () => void) {
    const registration = {
      id,
      onBusyCallback, 
      onCompleteCallback
    } as IRegistration;
    this.registrations.push(registration)
  }

  public unregister(id: string){
    this.registrations = this.registrations.filter(registration => registration.id !== id);
  }

  private handlePromiseComplete = () => {
    this.activePromises--;
    if (this.activePromises === 0) {
      this.isComplete = true;
      this.onComplete();
    }
  };

  private onComplete() {
    this.registrations.forEach(registration => {
      registration.onCompleteCallback();
    });
  }
}
