import * as React from 'react';
import styled from 'styled-components';

export default class SectionHeader extends React.Component {
    public render() {
        return (
            <StyledSectionHeader>
                {this.props.children}
            </StyledSectionHeader>
        );
    }
}

const StyledSectionHeader = styled.h3`
margin-bottom: 10px;
`
