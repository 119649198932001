import * as React from "react";
import { toast } from "react-toastify";
import Button from "Button";
import Form from "../Forms/Form";
import FormGroup from "../Forms/FormGroup";
import Textbox from "../Forms/Textbox";
import Loading from "../Loading";
import { LoadingTracker } from "../LoadingTracker";
import SectionHeader from "../SectionHeader";
import { configurationClient, UpdateConfigurationRequest } from "../http";

interface IManageWelcomeMessageState {
  welcomeMessage: string;
}

export default class ManageWelcomeMessage extends React.Component<any, IManageWelcomeMessageState> {
  private tracker: LoadingTracker;
  constructor(props: any) {
    super(props);

    this.tracker = new LoadingTracker();

    this.state = {
      welcomeMessage: ""
    };

    this.tracker.track(
      configurationClient
        .get()
        .then(response => {
          this.setState({ welcomeMessage: response.welcomeMessage || "" });
        })
        .catch(() => {
          toast.error("Uh-oh! Something went wrong while attempting to load the welcome message.");
        })
    );
  }

  public render() {
    return (
      <React.Fragment>
        <SectionHeader>Welcome Message</SectionHeader>
        <Loading tracker={this.tracker}>
          <Form onSubmit={this.handleSubmit}>
            <FormGroup>
              <Textbox multiline={true} rows={10} placeholder="Welcome Message *" onChange={this.handleFormFieldChange("welcomeMessage")} value={this.state.welcomeMessage} />
            </FormGroup>
            <Button type="submit" disabled={!this.isValid()}>
              Save
            </Button>
          </Form>
        </Loading>
      </React.Fragment>
    );
  }

  private isValid = () => {
    return true;
  };

  private handleSubmit = (e: any) => {
    e.preventDefault();

    this.tracker.track(
      configurationClient
        .update(new UpdateConfigurationRequest({ welcomeMessage: this.state.welcomeMessage }))
        .then(() => {
          toast.success("Welcome message successfully updated");
        })
        .catch(() => {
          toast.error("Uh-oh! Something went wrong while attempting to update the welcome message.");
        })
    );
  };

  private handleFormFieldChange = (fieldName: string) => (e: any) => {
    this.setState({ [fieldName]: e.target.value } as IManageWelcomeMessageState);
  };
}
