import * as moment from "moment";
import * as React from "react";
import styled from "styled-components";
import Card from "./Card";
import ListView from "./ListView";
import { purchasesClient, IPurchaseModel } from "./http";

class Purchases extends React.Component {
  public render() {
    return <ListView heading="Purchases" loadItems={this.loadPurchases} noItemsMessage="It looks like you haven't purchased anything yet." renderItem={this.renderItem} />;
  }

  private loadPurchases = () => {
    return purchasesClient.list();
  };

  private renderItem = (purchase: IPurchaseModel) => {
    return (
      <Card key={purchase.purchaseId}>
        <Purchase>
          <RewardName>
            {purchase.quantity} x {purchase.rewardName}
          </RewardName>
          <PurchasedAt
            title={moment
              .utc(purchase.purchasedAtUtc)
              .local()
              .format("MMMM Do YYYY, h:mm:ss a")}
          >
            {moment.utc(purchase.purchasedAtUtc).fromNow()}
          </PurchasedAt>
          <RewardDescription>{purchase.rewardDescription}</RewardDescription>
          <RewardPoints>{purchase.points} points</RewardPoints>
          {purchase.message && (
            <p><strong>Message:</strong> {purchase.message}</p>
          )}
        </Purchase>
      </Card>
    );
  };
}

export default Purchases;

const Purchase = styled.div`
  display: grid;
  grid-template: auto auto / min-content auto 1fr;
`;

const RewardName = styled.h3`
  grid-column: 1;
  grid-row: 1;
  white-space: nowrap;
`;

const RewardDescription = styled.div`
  grid-column: 1 / 4;
  grid-row: 2;
  margin-top: 5px;
`;

const RewardPoints = styled.div`
  align-self: end;
  grid-column: 2;
  grid-row: 1;
  margin-left: 10px;
`;

const PurchasedAt = styled.small`
  justify-self: end;
  align-self: center;
  grid-column: 3;
  grid-row: 1;
  white-space: nowrap;
`;
