import * as React from "react";
import { toast } from "react-toastify";
import Button from "../Button";
import ButtonToolbar from "../ButtonToolbar";
import Form from "../Forms/Form";
import FormGroup from "../Forms/FormGroup";
import Loading from "../Loading";
import { LoadingTracker } from "../LoadingTracker";
import { SetAllPointsRequest, teamMembersClient } from "../http";

export interface IConfirmPointSetFormProps {
  onSubmitted: () => void;
  onCancelled: () => void;
  initialState?: IConfirmPointSetFormState;
}

interface IConfirmPointSetFormState {
  pointTotal?: number;
}

export default class ConfirmPointSetForm extends React.Component<IConfirmPointSetFormProps, IConfirmPointSetFormState> {
  private readonly tracker: LoadingTracker;
  constructor(props: IConfirmPointSetFormProps) {
    super(props);

    const initialState: IConfirmPointSetFormState = {
     pointTotal: 0
    };

    this.state = {
      ...(props.initialState || initialState)
    };

    this.tracker = new LoadingTracker();
  }

  public render() {
    return (
      <Loading tracker={this.tracker}>
        <Form onSubmit={this.handleSet}>
          <FormGroup>
            <h3>Are you sure you want to set all team members "Points to Allocate" to {this.state.pointTotal ?? 0}</h3>
          </FormGroup>
          <ButtonToolbar>
            <Button onClick={this.handleCancel} variant="secondary">
              Cancel
            </Button>
            <Button type="submit">
              Set Points
            </Button>
          </ButtonToolbar>
        </Form>
      </Loading>
    );
  }

 

  private handleSet = (e: any) => {
    const setInternal = async () => {
      try {
        let request = new SetAllPointsRequest({pointTotal: this.state.pointTotal ?? 0});
        await teamMembersClient.clearPoints(request);
        toast.success("All allocate points have been set to " + this.state.pointTotal ?? 0 + "");
        this.props.onSubmitted();
      } catch (error) {
        if (error.response.status === 400) {
          toast.error(error.response.data.title);
        } else {
          toast.error("Uh-oh! Something went wrong while trying to delete the team member.");
        }
      }
    };

    e.preventDefault();
    this.tracker.track(setInternal());
  };

  private handleCancel = () => {
    this.props.onCancelled();
  };
}
