import * as React from "react";
import styled, { css } from "styled-components";
import LightenDarkenColor from "./LightenDarken";

interface IButtonProps {
  onClick?: (e: any) => Promise<any> | void;
  disabled?: boolean;
  variant?: "primary" | "secondary" | "danger";
  type?: "submit" | "button";
}

class Button extends React.Component<IButtonProps, any> {
  constructor(props: IButtonProps = { onClick: () => {}, variant: "primary", type: "button" }) {
    super(props);
  }

  public render() {
    return (
      <StyledButton type={this.props.type || "button"} {...this.props}>
        {this.props.children}
      </StyledButton>
    );
  }
}

export default Button;

const StyledButton = styled.button`
  outline: none;
  border: 0;
  background: none;
  background-color: ${(props: any) => props.theme.primaryColor};
  color: ${(props: any) => props.theme.secondaryColor};
  padding: 9px 16px;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 16px;

  @media (max-width: 767px) {
    font-size: 14px;
    padding: 6px 10px;
  }

  ${(props: any) =>
    props.disabled &&
    css`
      cursor: not-allowed;
      opacity: 0.6;
    `};

  ${(props: any) =>
    !props.disabled &&
    css`
      &:active,
      &:hover {
        background-color: ${LightenDarkenColor(props.theme.primaryColor, 20)};
      }
    `};

  ${(props: any) =>
    props.variant === "secondary" &&
    css`
      border: 1px solid ${props.theme.secondaryColor};
      background-color: ${props.theme.tertiaryColor};
      color: ${props.theme.secondaryColor};

      ${!props.disabled &&
        css`
          &:active,
          &:hover {
            background-color: ${LightenDarkenColor(props.theme.tertiaryColor, -20)};
          }
        `};
    `}

  ${(props: any) =>
    props.variant === "danger" &&
    css`
      border: 1px solid ${props.theme.dangerColor};
      background-color: ${props.theme.dangerColor};
      color: ${props.theme.tertiaryColor};

      ${!props.disabled &&
        css`
          &:active,
          &:hover {
            background-color: ${LightenDarkenColor(props.theme.dangerColor, 20)};
          }
        `};
    `}
`;
