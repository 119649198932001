import * as moment from "moment";
import * as React from "react";
import { toast } from "react-toastify";
import styled from "styled-components";
import Button from "../Button";
import ButtonToolbar from "../ButtonToolbar";
import Card from "../Card";
import ListView from "../ListView";
import Loading from "../Loading";
import { LoadingTracker } from "../LoadingTracker";
import AchievementForm from "./AchievementForm";
import {
  achievementsClient,
  IAchievementModel,
  ITeamMemberModel,
  TeamMemberModel,
  teamMembersClient,
} from "../http";
import Select from "Forms/Select";

interface IManageAchievementsProps {
  profile: ITeamMemberModel | undefined;
  reloadProfile: () => void;
}

interface IManageAchievementModel {
  teamMemberId?: string;
  teamMembers?: ITeamMemberModel[];
  filterActive: boolean;
}

export default class ManageAchievements extends React.Component<
  IManageAchievementsProps,
  IManageAchievementModel
> {
  private loadingTracker: LoadingTracker;

  constructor(props: IManageAchievementsProps) {
    super(props);

    const initialState: IManageAchievementModel = {
      teamMemberId: "",
      teamMembers: [],
      filterActive: false,
    };

    this.state = initialState;

    this.loadingTracker = new LoadingTracker();
  }

  public componentDidMount() {
    const loadTeamMembers = async () => {
      try {
        var teamMembers = await teamMembersClient.list();
        this.setState({
          teamMembers: teamMembers,
        });

        if (!this.state.teamMemberId) {
           this.setState({ teamMemberId: teamMembers[1]?.teamMemberId! });
        }

        window.scrollTo(0, 0);
      } catch {
        toast.error(
          "Uh-oh! An error occurred while loading available team members"
        );
      }
    };

    this.loadingTracker.track(loadTeamMembers());
  }

  private handleFilterChange = (e: any) => {
    this.setState({ teamMemberId: e.target.value });
  };

  public render() {
    return (
        <ListView
          heading="Manage Achievements"
          loadItems={this.getAchievements}
          renderItem={this.renderAchievement}
          noItemsMessage="It doesn't look like there are any achievements yet."
          addForm={this.renderAddForm}
          addText="Add Achievement"
          editForm={this.renderEditForm}
          editText="Edit Achievement"
          renderFilter={this.renderFilter}
          filterItems={this.filterItems}
          loadFilterItems={this.getTeamMembers}
        />
    );
  }

  private renderFilter = (items: ITeamMemberModel[], onChange: (e:any) => void, value: string) => {
    return (
      <Filter> 
      <label >
      Filter
      <Select
        placeholder="Recipient *"
        onChange={onChange}
        value={value}
      >
        {items &&
          items.map((t) => (
            <option key={t.teamMemberId ?? "filter"} value={t.teamMemberId}>
              {t.name}
            </option>
          ))}
      </Select>
    </label>
    </Filter>)
  }

  private filterItems = (items: IAchievementModel[], id: any) => {
    if(!id || id == "No Filter"){
      return items;
    }
    return items.filter(x => x.teamMemberId == id);
  }

  private getAchievements = async () => {
    return await achievementsClient.listAll();
  };

  private getTeamMembers = async () => {
    var teamMembers =  await teamMembersClient.list()
    teamMembers.unshift({
      teamMemberId: undefined,
      isDeleted: false,
      name: "No Filter",
    } as TeamMemberModel);
    return teamMembers
  };

  private renderAchievement = (
    achievement: IAchievementModel,
    refreshItems: () => void,
    editItem: (achievement: IAchievementModel) => void
  ) => {
    return (
      <Loading tracker={this.loadingTracker} key={achievement.achievementId}>
        <Card>
          <Achievement>
            <AchievedBy>{achievement.teamMemberName}</AchievedBy>
            <AchievementName>{achievement.name}</AchievementName>
            <AchievementDescription>
              {achievement.description}
            </AchievementDescription>
            <PointsEarned>{achievement.pointsEarned} points</PointsEarned>
            <AchievedAt
              title={moment
                .utc(achievement.achievedAtUtc)
                .local()
                .format("MMMM Do YYYY, h:mm:ss a")}
            >
              {moment.utc(achievement.achievedAtUtc).fromNow()}
            </AchievedAt>
            {achievement.awardedByTeamMemberId && (
              <AwardedBy>
                Awarded By: {achievement.awardedByTeamMemberName}
              </AwardedBy>
            )}
            <Actions>
              <ButtonToolbar>
                <Button onClick={editItem} variant="secondary">
                  Edit
                </Button>
                <Button
                  onClick={this.deleteAchievement(achievement, refreshItems)}
                  variant="danger"
                >
                  Delete
                </Button>
              </ButtonToolbar>
            </Actions>
          </Achievement>
        </Card>
      </Loading>
    );
  };

  private renderAddForm = (
    onItemSaved: () => void,
    onAddCancelled: () => void
  ) => {
    const onSubmitted = () => {
      onItemSaved();
      this.props.reloadProfile();
    };
    return (
      <AchievementForm
        profile={this.props.profile}
        onCancelled={onAddCancelled}
        onSubmitted={onSubmitted}
      />
    );
  };

  private renderEditForm = (
    item: IAchievementModel,
    onItemSaved: () => void,
    onEditCancelled: () => void
  ) => {
    const onSubmitted = () => {
      onItemSaved();
      this.props.reloadProfile();
    };
    return (
      <AchievementForm
        initialState={item}
        profile={this.props.profile}
        onCancelled={onEditCancelled}
        onSubmitted={onSubmitted}
      />
    );
  };

  private deleteAchievement =
    (achievement: IAchievementModel, onRewardDeleted: () => void) =>
    async () => {
      const deleteInternal = async () => {
        try {
          await achievementsClient.delete(achievement.achievementId!);
          toast.success("Achievement '" + achievement.name + "' deleted");
          onRewardDeleted();
          this.props.reloadProfile();
        } catch (error) {
          if (error.response.status === 400) {
            toast.error(error.response.data.title);
          } else {
            toast.error(
              "Uh-oh! Something went wrong while trying to delete the achievement."
            );
          }
        }
      };

      this.loadingTracker.track(deleteInternal());
    };
}

const Achievement = styled.div`
  display: grid;
  grid-template-rows: auto auto auto auto auto;
  grid-template-columns: min-content auto 1fr;
`;

const AchievementName = styled.h3`
  grid-column: 1;
  grid-row: 2;
  white-space: nowrap;
`;

const AchievementDescription = styled.div`
  grid-column: 1 / 4;
  grid-row: 3;
  margin-top: 5px;
`;

const PointsEarned = styled.div`
  align-self: end;
  grid-column: 2;
  grid-row: 2;
  margin-left: 10px;
`;

const AchievedAt = styled.small`
  justify-self: end;
  align-self: center;
  grid-column: 3;
  grid-row: 1;
  white-space: nowrap;
`;

const AchievedBy = styled.span`
  grid-column: 1 / 3;
  grid-row: 1;
  margin-bottom: 5px;
`;

const AwardedBy = styled.span`
  grid-column: 1 / 3;
  grid-row: 4;
  margin-bottom: 5px;
`;

const Actions = styled.div`
  margin-top: 10px;
  grid-column: 1/4;
  grid-row: 5;
`;

const Filter = styled.div`
  margin-top: 10px;
  margin-bottom: 20px
`;
