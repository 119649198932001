import * as React from "react";
import PageHeader from "../PageHeader";
import ManageWelcomeMessage from "./ManageWelcomeMessage";
import ButtonToolbar from "ButtonToolbar";
import Button from "Button";
import SwipeableViews from "react-swipeable-views";
import { LoadingTracker } from "LoadingTracker";
import ConfirmPointSetForm from "./ConfirmPointSetForm";

export interface IManageBulkActionsProps {
  activeTab: number;
  isClearing: boolean;
  isResetting: boolean;

}

export default class ManageBulkActions extends React.Component<
  IManageBulkActionsProps,
  any
> {
  constructor(props: any) {
    super(props);

    this.state = {
      activeTab: 0,
      isClearing: false,
      isResetting: false
    };
  }

  private hideForm = () => {
    this.setState({ activeTab: 0, isClearing: false, isResetting: false });
  };

  private showClearingForm = () => {
    this.setState({ activeTab: 1, isClearing: true });
  };
  private showResettingForm = () => {
    this.setState({ activeTab: 1, isResetting: true });
  };

  public render() {
    return (
      <SwipeableViews
        index={this.state.activeTab}
        disabled={true}
        slideStyle={{ minHeight: "125px" }}
      >
        <div>
        <PageHeader>Bulk Actions</PageHeader>
        <ButtonToolbar>
          <Button onClick={this.showClearingForm}>Clear all Allocate Points</Button>
          <Button onClick={this.showResettingForm}>Set all Allocate Points to 1000</Button>
        </ButtonToolbar>
        </div>
        <div>
        {this.state.activeTab === 1 && (this.state.isResetting || this.state.isClearing) &&
            <ConfirmPointSetForm initialState={{pointTotal: this.state.isClearing ? 0 : 1000}} onCancelled={this.hideForm} onSubmitted={this.hideForm}/>
        }
        </div>

      </SwipeableViews>
    );
  }
}
