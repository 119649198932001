import * as React from "react";
import { toast } from "react-toastify";
import styled from "styled-components";
import Button from "./Button";
import ButtonToolbar from "./ButtonToolbar";
import Card from "./Card";
import Textbox from "./Forms/Textbox";
import Loading from "./Loading";
import { LoadingTracker } from "./LoadingTracker";
import { IRewardModel, purchasesClient, CreatePurchaseRequest, rewardsClient } from "./http";

interface IRewardItemProps {
  reward: IRewardModel;
  availablePoints?: number;
  onRewardPurchased?: () => void;
  onRewardDeleted?: () => void;
  canDelete?: boolean;
  canEdit?: boolean;
  onEditReward?: (reward: IRewardModel) => void;
}

interface IRewardItemState {
  quantity: number;
  message: string;
}

export default class RewardItem extends React.Component<IRewardItemProps, IRewardItemState> {
  public loadingTracker: LoadingTracker;

  constructor(props: IRewardItemProps) {
    super(props);

    this.loadingTracker = new LoadingTracker();

    this.state = {
      quantity: 1,
      message: ""
    };
  }

  public render() {
    return (
      <Loading tracker={this.loadingTracker}>
        <Card>
          <Reward>
            <RewardImage src={this.props.reward.imageUrl || "http://lorempixel.com/150/150/abstract"} />
            <Name>{this.props.reward.name}</Name>
            <Points>{this.rewardCost()} points</Points>
            <Description>{this.props.reward.description}</Description>
            {this.props.canEdit && (
              <ShowMessageBox>
                {this.props.reward.showMessageBox
                  ? "The message box will be displayed for this reward"
                  : "The message box won't be displayed for this reward"}
              </ShowMessageBox>
            )}
            {!this.props.canEdit && this.props.reward.showMessageBox && (
              <Textbox placeholder={"Message to add"}
                       type="text"
                       value={this.state.message}
                       onChange={this.handleMessageChange}
                       multiline={true}
                       rows={4}
                       width="20rem"/>                       
            )}

            <Actions>
              <ButtonToolbar>
                {this.props.availablePoints !== undefined && (
                  <React.Fragment>
                    <Textbox type="number" 
                             placeholder="Quantity" 
                             value={this.state.quantity} 
                             onChange={this.handleQuantityChange} width="50px" />
                    <Button disabled={this.props.availablePoints < this.rewardCost()} onClick={this.purchaseReward}>
                      {this.props.availablePoints >= this.rewardCost() ? "Purchase" : this.rewardCost() - this.props.availablePoints + " more needed"}
                    </Button>
                  </React.Fragment>
                )}
                {this.props.canEdit && (
                  <Button onClick={this.editReward} variant="secondary">
                    Edit
                  </Button>
                )}
                {this.props.canDelete && (
                  <Button onClick={this.deleteReward} variant="danger">
                    Delete
                  </Button>
                )}
              </ButtonToolbar>
            </Actions>
          </Reward>
        </Card>
      </Loading>
    );
  }

  private rewardCost = () => {
    return (this.props.reward.points || 0) * this.state.quantity;
  };

  private purchaseReward = () => {
    this.loadingTracker.track(
      purchasesClient
        .create(new CreatePurchaseRequest({ 
          rewardId: this.props.reward.rewardId!, 
          quantity: +this.state.quantity!,
          message: this.state.message
        }))
        .then(() => {
          toast.success("Reward '" + this.props.reward.name + "' purchased");
          if (this.props.onRewardPurchased) {
            this.props.onRewardPurchased();
          }
        })
        .catch(() => {
          toast.error("Uh-oh! Something went wrong while trying to purchase the reward.");
        })
    );
  };

  private deleteReward = () => {
    this.loadingTracker.track(
      rewardsClient
        .delete(this.props.reward.rewardId!)
        .then(() => {
          toast.success("Reward '" + this.props.reward.name + "' deleted");
          if (this.props.onRewardDeleted) {
            this.props.onRewardDeleted();
          }
        })
        .catch(error => {
          if (error.response.status === 400) {
            toast.error(error.response.data.title);
          } else {
            toast.error("Uh-oh! Something went wrong while trying to delete the reward.");
          }
        })
    );
  };

  private editReward = () => {
    if (this.props.onEditReward) {
      this.props.onEditReward(this.props.reward);
    }
  };

  private handleQuantityChange = (e: any) => {
    const newValue = e.target.value;
    if (newValue > 0) {
      this.setState({ quantity: newValue });
    }
  };
  
  private handleMessageChange = (e: any) => {
    this.setState({
      message: e.target.value
    });
  };
}

const Reward = styled.div`
  display: grid;
  grid-template-rows: auto auto auto max-content;
  grid-template-columns: auto 1fr;
  grid-row-gap: 10px;

  @media (max-width: 767px) {
    grid-row-gap: 5px;
  }
`;

const Name = styled.div`
  font-weight: bold;
  font-size: 22px;
  grid-row: 1;
  grid-column: 2;

  @media (max-width: 767px) {
    font-size: 18px;
  }
`;

const Points = styled.div`
  grid-row: 2;
  grid-column: 2;
  @media (max-width: 767px) {
    font-size: 13px;
  }
`;

const Description = styled.div`
  font-size: 16px;
  grid-row: 3;
  grid-column: 2;

  @media (max-width: 767px) {
    font-size: 14px;
  }
`;

const ShowMessageBox = styled.div`
  font-size: 16px;
  grid-row: 4;
  grid-column: 2;

  @media (max-width: 767px) {
    font-size: 14px;
  }
`;

const Actions = styled.div`
  grid-row: 5;
  grid-column: 2;
`;

const RewardImage = styled.img`
  grid-row: 1 / 5;
  grid-column: 1;
  align-self: center;
  position: relative;
  margin-right: 15px;
  max-height: 140px;
  width: 140px;

  @media (max-width: 767px) {
    max-height: 110px;
    max-width: 110px;
    margin-right: 10px;
  }
`;
