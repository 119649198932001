import * as React from 'react';
import styled from 'styled-components';

export interface IFormGroupProps {
    foo?: string
}

export default class FormGroup extends React.Component<IFormGroupProps, any> {
  public render() {
    return (
      <StyledFormGroup>
        {this.props.children}
      </StyledFormGroup>
    );
  }
}

const StyledFormGroup = styled.div`
    margin-bottom: 15px;
`