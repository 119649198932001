import { Md5 } from "md5-typescript";
import * as React from "react";
import styled from "styled-components";

export interface IGravatarProps {
  email: string;
  size: number;
}

export default class Gravatar extends React.Component<IGravatarProps, any> {
  public render() {
    return <StyledGravatar size={this.props.size} src={"https://www.gravatar.com/avatar/" + Md5.init(this.props.email.toLowerCase()) + "?s=" + this.props.size + "&d=retro "} />;
  }
}

const StyledGravatar = styled.img<{ size: number }>`
  border-radius: ${(props: any) => props.size / 2 + "px"};
`;
