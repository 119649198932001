import * as React from "react";
import styled from "styled-components";

export interface IFormProps {
  name?: string,
  onSubmit: (e: any) => void
}

export default class Form extends React.Component<IFormProps, any> {
  public render() {
    return <StyledForm name={this.props.name} onSubmit={this.props.onSubmit}>{this.props.children}</StyledForm>;
  }
}

const StyledForm = styled.form`
  max-width: 500px;
`