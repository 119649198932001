import * as React from "react";
import { Route, withRouter } from "react-router";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styled, { createGlobalStyle, ThemeProvider } from "styled-components";
import Achievements from "./Achievements";
import ManageAchievements from "./admin/ManageAchievements";
import ManageConfiguration from "./admin/ManageConfiguration";
import ManagePurchases from "./admin/ManagePurchases";
import ManageRewards from "./admin/ManageRewards";
import ManageTeamMembers from "./admin/ManageTeamMembers";
import Container from "./Container";
import Home from "./Home";
import Navbar from "./Navbar";
import Purchases from "./Purchases";
import Theme, { GlobalStyle } from "./Theme";
import { teamMembersClient } from "./http";
import {AchievementsGiven} from "./AchievementsGiven";
import {Reports} from "./admin/Reports";
import ManageBulkActions from "admin/ManageBulkActions";

class Layout extends React.Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      profile: null
    };
  }
  public componentDidMount() {
    this.loadProfile();
  }

  public render() {
    return (
      <ThemeProvider theme={Theme}>
          <AppContainer>
            <GlobalStyle />
            <Navbar profile={this.state.profile} />
            <MainContent>
              <Container>
                <Route exact={true} path="/" render={this.renderHome} />
                <Route path="/purchases" component={Purchases} />
                <Route path="/achievements" render={this.renderAchievements} />
                <Route path="/achievements-given" render={this.renderAchievementsGiven}/>
                <Route path="/admin/achievements" render={this.renderManageAchievements} />
                <Route path="/admin/purchases" component={ManagePurchases} />
                <Route path="/admin/rewards" component={ManageRewards} />
                <Route path="/admin/configuration" component={ManageConfiguration} />
                <Route path="/admin/team-members" component={ManageTeamMembers} />
                <Route path="/admin/reports" component={Reports}/>
                <Route path="/admin/bulkActions" component={ManageBulkActions}/>
              </Container>
            </MainContent>
            <ToastStyle />
            <ToastContainer />
          </AppContainer>
      </ThemeProvider>
    );
  }

  private loadProfile = () => {
    teamMembersClient
      .me()
      .then(response => {
        this.setState({ profile: response });
        const theWindow = window as any;
        if (theWindow.loading_screen) {
          theWindow.loading_screen.finish();
          theWindow.loading_screen = null;
        }
      })
      .catch(() => {
        toast.error("Uh-oh! Something went wrong when trying to get your profile.");
      });
  };

  private renderHome = () => {
    return <Home profile={this.state.profile} reloadProfile={this.reloadProfile} />;
  };

  private renderManageAchievements = () => {
    return <ManageAchievements profile={this.state.profile} reloadProfile={this.reloadProfile} />;
  }

  private renderAchievements = () => {
    return <Achievements profile={this.state.profile} reloadProfile={this.reloadProfile} />;
  }
  
  private renderAchievementsGiven = () => (<AchievementsGiven profile={this.state.profile}/>);

  private reloadProfile = () => {
    this.loadProfile();
  };
}

export default withRouter(Layout);

const AppContainer = styled.div``;

const MainContent = styled.div`
  margin-top: ${(props: any) => props.theme.headerHeight + 10 + "px"};
`;

// tslint:disable-next-line:no-unused-expression
const ToastStyle = createGlobalStyle`
    .Toastify__toast-container {
    top: 60px;

    @media (max-width: 767px){
      left: 10px;
      right: 10px;
      width: auto;
    }

    .Toastify__toast-body{
      color: #fff;  
    }
  }
`;
