import * as React from "react";
import styled from "styled-components";

class Container extends React.Component {
  public render() {
    return <StyledContainer>{this.props.children}</StyledContainer>;
  }
}

export default Container;

const StyledContainer = styled.div`
  margin: 0 10px;

  @media (min-width: 768px) {
    width: 748px;
    margin: 0 auto;
  }

  @media (min-width: 992px) {
    width: 972px;
    margin: 0 auto;
  }

  @media (min-width: 1200px) {
    width: 1180px;
    margin: 0 auto;
  }
`;
