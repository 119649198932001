import * as React from "react";
import { slide as Menu } from "react-burger-menu";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import { isUserInRole } from "./ActiveDirectory";
import Container from "./Container";
import Gravatar from "./Gravatar";
import LightenDarkenColor from "./LightenDarken";
import { ITeamMemberModel } from "./http";

interface INavbarProp {
  profile: ITeamMemberModel | undefined;
}

interface INavbarState {
  isSideMenuOpen: boolean;
  isAdminMenuOpen: boolean;
}

class Navbar extends React.Component<any, INavbarState> {
  constructor(props: INavbarProp) {
    super(props);

    this.state = {
      isAdminMenuOpen: false,
      isSideMenuOpen: false
    };
  }

  public render() {
    const navbarMenu = (
      <NavbarMenu>
        <NavbarMenuItem>
          <Link to="/" onClick={this.toggleSideMenu}>
            Rewards shop
          </Link>
        </NavbarMenuItem>
        <NavbarMenuItem>
          <Link to="/purchases" onClick={this.toggleSideMenu}>
            Purchases
          </Link>
        </NavbarMenuItem>
        <NavbarMenuItem>
          <Link to="/achievements" onClick={this.toggleSideMenu}>
            Your achievements
          </Link>
        </NavbarMenuItem>
        <NavbarMenuItem>
          <Link to="/achievements-given" onClick={this.toggleSideMenu}>
            Achievements given
          </Link>
        </NavbarMenuItem>
        {isUserInRole("Admin") && (
          <NavbarMenuItem>
            <DropdownToggle
              // tslint:disable-next-line:jsx-no-lambda
              onClick={() => {
                this.setState({ isAdminMenuOpen: true });
                const handleWindowClick = () => {
                  this.setState({ isAdminMenuOpen: false });
                  window.removeEventListener("click", handleWindowClick);
                };
                window.setTimeout(() => {
                  window.addEventListener("click", handleWindowClick);
                }, 0);
              }}
            >
              Admin
            </DropdownToggle>
            <DropdownMenu isOpen={this.state.isAdminMenuOpen}>
              <NavbarMenuItem>
                <Link to="/admin/rewards" onClick={this.toggleSideMenu}>
                  Rewards
                </Link>
              </NavbarMenuItem>
              <NavbarMenuItem>
                <Link to="/admin/achievements" onClick={this.toggleSideMenu}>
                  Achievements
                </Link>
              </NavbarMenuItem>
              <NavbarMenuItem>
                <Link to="/admin/purchases" onClick={this.toggleSideMenu}>
                  Purchases
                </Link>
              </NavbarMenuItem>
              <NavbarMenuItem>
                <Link to="/admin/team-members" onClick={this.toggleSideMenu}>
                  Team Members
                </Link>
              </NavbarMenuItem>
              <NavbarMenuItem>
                <Link to="/admin/reports" onClick={this.toggleSideMenu}>
                  Reports
                </Link>
              </NavbarMenuItem>
              <NavbarMenuItem>
                <Link to="/admin/configuration" onClick={this.toggleSideMenu}>
                  Configuration
                </Link>
              </NavbarMenuItem>
              <NavbarMenuItem>
                <Link to="/admin/bulkActions" onClick={this.toggleSideMenu}>
                  Bulk Actions
                </Link>
              </NavbarMenuItem>
            </DropdownMenu>
          </NavbarMenuItem>
        )}
      </NavbarMenu>
    );

    return (
      <div>
        <NavbarContainer>
          <Container>
            <NavbarInner>
              <HamburgerIcon onClick={this.toggleSideMenu}>
                <div>
                  <div />
                  <div />
                  <div />
                </div>
              </HamburgerIcon>
              <Brand>
                <Link to="/">SixPivot Rewards</Link>
              </Brand>
              <NavbarMenuContainer>{navbarMenu}</NavbarMenuContainer>
              {this.props.profile && (
                <Profile>
                  <Gravatar email={this.props.profile.email} size={30} />
                  <span>
                    {this.props.profile.name}
                    <small>
                      {this.props.profile.availablePoints} points to spend<br/>
                      {this.props.profile.availablePointsToAllocate} points to be allocated
                    </small>
                  </span>
                </Profile>
              )}
            </NavbarInner>
          </Container>
        </NavbarContainer>

        <StyledMenu isOpen={this.state.isSideMenuOpen} onStateChange={this.onMenuStateChange} customBurgerIcon={false}>
          {navbarMenu}
        </StyledMenu>
      </div>
    );
  }

  private toggleSideMenu = () => {
    this.setState({ isSideMenuOpen: !this.state.isSideMenuOpen });
  };

  private onMenuStateChange = (state: any) => {
    this.setState({ isSideMenuOpen: state.isOpen });
  };
}

export default Navbar;

const NavbarContainer = styled.div`
  background: ${(props: any) => props.theme.primaryColor};
  position: fixed;
  height: ${(props: any) => props.theme.headerHeight + "px"};
  left: 0;
  right: 0;
  top: 0;
  z-index: 1000;
  span,
  a,
  small {
    color: ${(props: any) => props.theme.secondaryColor};
    text-decoration: none;
  }
`;

const NavbarInner = styled.div`
  display: flex;
  align-items: center;
  height: ${(props: any) => props.theme.headerHeight + "px"};
`;

const Brand = styled.h2`
  color: ${(props: any) => props.theme.secondaryColor};
  margin: 0;
  display: inline-block;
  flex: 1;

  @media (max-width: 768px) {
    font-size: 20px;
  }
`;

const NavbarMenu = styled.div`
  display: inline-block;
`;

const NavbarMenuItem = styled.div`
  display: inline-block;
  a {
    padding: 0 10px;
    display: inline-block;
    height: ${(props: any) => props.theme.headerHeight + "px"};
    line-height: ${(props: any) => props.theme.headerHeight + "px"};
    &:hover {
      background-color: ${(props: any) => LightenDarkenColor(props.theme.primaryColor, 20)};
    }
  }
`;

const Profile = styled.div`
  padding-left: 10px;
  display: grid;
  grid-template: auto auto / auto auto;

  img {
    grid-row: 1 / span 2;
    align-self: center;
    margin-right: 5px;
  }

  span {
    align-self: end;
  }

  small {
    align-self: start;
  }

  span,
  small {
    display: block;
    line-height: initial;
  }
  span {
    font-weight: bold;
  }
`;

const DropdownToggle = styled.a`
  cursor: pointer;
  display: block;
  @media (max-width: 768px) {
    cursor: default;
  }
`;

const DropdownMenu = styled.div<{ isOpen: boolean }>`
  position: absolute;
  display: block;
  
  ${props =>
    !props.isOpen &&
    css`
      display: none;
    `}
  background: ${(props: any) => LightenDarkenColor(props.theme.primaryColor, -20)};

  ${NavbarMenuItem} {
    display: block;
    min-width: 200px;
    a {
      display: block;
    }
  }
`;

const StyledMenu = styled(Menu)`
  @media (min-width: 768px) {
    display: none;
  }

  top: 0;

  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 24px;
    width: 24px;
  }

  /* Color/shape of close button cross */
  .bm-cross {
    background: ${(props: any) => props.theme.secondaryColor};
  }

  /* General sidebar styles */
  .bm-menu {
    background: ${(props: any) => LightenDarkenColor(props.theme.primaryColor, -20)};
    padding: 2.5em 0 0;
    font-size: 1.15em;
  }

  ${NavbarMenuItem} {
    display: block;
    a {
      color: ${(props: any) => props.theme.secondaryColor};
      text-decoration: none;
      display: block;
      padding: 15px 10px;
      line-height: inherit;
      height: auto;
    }
  }
  ${DropdownMenu} {
    position: initial;
    display: block;
    a {
      padding-left: 30px;
    }
  }
`;

const NavbarMenuContainer = styled.div`
  display: none;
  @media (min-width: 768px) {
    display: block;
  }
`;

const HamburgerIcon = styled.div`
  @media (min-width: 768px) {
    display: none;
  }
  margin-right: 20px;
  height: 20px;
  align-self: center;
  cursor: pointer;

  div > div {
    width: 25px;
    height: 4px;
    background-color: ${(props: any) => props.theme.secondaryColor};
    margin: 4px 0;
    border-radius: 3px;

    &:first-child {
      margin-top: 0px;
    }
  }
`;
