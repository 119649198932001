import { LoadingTracker } from "./LoadingTracker";

import * as React from "react";
import styled, { css, keyframes } from "styled-components";

interface ILoadingProps {
  tracker: LoadingTracker;
}

interface ILoadingState {
  isLoading: boolean;
}

class Loading extends React.Component<ILoadingProps, ILoadingState> {
  private id: string;
  constructor(props: ILoadingProps) {
    super(props);
    this.id =  Math.random().toString(36).substr(2, 9);

    this.state = {
      isLoading: !props.tracker.isComplete
    };
  }

  public componentDidMount() {    
    this.props.tracker.register(this.id, 
      () => {
        this.setState({ isLoading: true });
      },
      () => {
        this.setState({ isLoading: false });
      }
    );
  }

  public componentWillUnmount() {
    this.props.tracker.unregister(this.id);
  }

  public render() {
    return (
      <LoadingContainer>
        <LoadingContent>{this.props.children}</LoadingContent>

        {this.state.isLoading && <LoadingOverlay />}

        {this.state.isLoading && (
          <LoadingSpinnerContainer>
            <LoadingSpinner />
          </LoadingSpinnerContainer>
        )}
      </LoadingContainer>
    );
  }
}

export default Loading;

const LoadingContainer = styled.div`
  position: relative;
  min-height: 80px;
`;

const LoadingOverlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.8;
  background-color: white;
`;

const LoadingSpinnerContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: grid;
  justify-content: center;
  align-content: center;
`;

const spinnerKeyframes = keyframes`
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
`;

const LoadingSpinner = styled.div`
  display: inline-block;
  width: 64px;
  height: 64px;
  &:after {
    content: " ";
    display: block;
    width: 46px;
    height: 46px;
    margin: 1px;
    border-radius: 50%;
    ${(props) => css`
      border: ${props.theme.secondaryColor} 5px solid;
      border-color: ${props.theme.secondaryColor} transparent
        ${props.theme.secondaryColor} transparent;
      animation: ${spinnerKeyframes} 1.2s linear infinite;
    `};
  }
`;

const LoadingContent = styled.div`
min-height: 64px;
`;
