import * as React from "react";
import ListView from "../ListView";
import RewardItem from "../RewardItem";
import RewardForm from "./RewardForm";
import { rewardsClient, IRewardModel } from "../http";

export default class ManageRewards extends React.Component {
  public render() {
    return (
      <div>
        <ListView
          heading="Manage Rewards"
          loadItems={this.getRewards}
          renderItem={this.renderItem}
          noItemsMessage="There aren't any rewards. Maybe you should add one?"
          addForm={this.addRewardForm}
          addText="Add Reward"
          editForm={this.editRewardForm}
          editText="Edit Reward"
        />
      </div>
    );
  }

  private getRewards = async () => {
    return await rewardsClient.list();
  };

  private renderItem = (reward: IRewardModel, refreshItems: () => void, editItem: (reward: IRewardModel) => void) => {
    return <RewardItem key={reward.rewardId} reward={reward} canDelete={true} onRewardDeleted={refreshItems} canEdit={true} onEditReward={editItem} />;
  };

  private addRewardForm = (onItemAdded: () => void, onAddCancelled: () => void) => {
    return <RewardForm onCancelled={onAddCancelled} onSubmitted={onItemAdded} />;
  };

  private editRewardForm = (reward: IRewardModel, onItemSaved: () => void, onEditCancelled: () => void) => {
    return <RewardForm onCancelled={onEditCancelled} onSubmitted={onItemSaved} initialState={reward} />;
  };
}
