import * as React from "react";
import { toast } from "react-toastify";
import Button from "../Button";
import ButtonToolbar from "../ButtonToolbar";
import Form from "../Forms/Form";
import FormGroup from "../Forms/FormGroup";
import Select from "../Forms/Select";
import Textbox from "../Forms/Textbox";
import Loading from "../Loading";
import { LoadingTracker } from "../LoadingTracker";
import { ITeamMemberModel, CreateTeamMemberRequest, ICreateTeamMemberRequest, activeDirectoryUsersClient, IActiveDirectoryUserModel, teamMembersClient } from "../http";

export interface ITeamMemberFormProps {
  onSubmitted: () => void;
  onCancelled: () => void;
  initialState?: ITeamMemberModel;
}

interface ITeamMemberFormState {
  activeDirectoryUsers?: IActiveDirectoryUserModel[];
  activeDirectoryUser?: IActiveDirectoryUserModel;
}

export default class TeamMemberForm extends React.Component<ITeamMemberFormProps, ITeamMemberFormState> {
  private tracker: LoadingTracker;
  constructor(props: ITeamMemberFormProps) {
    super(props);

    this.state = {};

    this.tracker = new LoadingTracker();
  }

  public componentDidMount() {
    const loadActiveDirectoryUsers = async () => {
      try {
        const activeDirectoryUsers = await activeDirectoryUsersClient.list();
        
        this.setState({
          activeDirectoryUsers: activeDirectoryUsers
        });

        if (!this.state.activeDirectoryUser) {
          this.setState({ activeDirectoryUser: activeDirectoryUsers[0] });
        }

        window.scrollTo(0, 0);
      } catch {
        toast.error("Uh-oh! An error occurred while loading available users");
      }
    };

    this.tracker.track(loadActiveDirectoryUsers());
  }

  public render() {
    return (
      <Loading tracker={this.tracker}>
        <Form onSubmit={this.handleAdd}>
          <FormGroup>
            <Select placeholder="Team Member *" onChange={this.handleActiveDirectoryUserChange} value={this.state.activeDirectoryUser?.id}>
              {this.state.activeDirectoryUsers === undefined && <option>Team Member *</option>}
              {this.state.activeDirectoryUsers &&
                this.state.activeDirectoryUsers.map(t => (
                  <option key={t.id} value={t.id}>
                    {t.fullName} ({t.email})
                  </option>
                ))}
            </Select>
          </FormGroup>
          <FormGroup>
            <Textbox placeholder="Name *" width="300px" value={this.state.activeDirectoryUser?.fullName} disabled={true} />
          </FormGroup>
          <FormGroup>
            <Textbox placeholder="Email *" width="300px" value={this.state.activeDirectoryUser?.email} disabled={true} />
          </FormGroup>
          <ButtonToolbar>
            <Button onClick={this.handleCancel} variant="secondary">
              Cancel
            </Button>
            <Button type="submit" disabled={!this.isValid()}>
              Add TeamMember
            </Button>
          </ButtonToolbar>
        </Form>
      </Loading>
    );
  }

  private handleActiveDirectoryUserChange = (e: any) => {
    const adUser = this.state.activeDirectoryUsers?.find(u => u.id === e.target.value);
    this.setState({ activeDirectoryUser: adUser });
  };

  private handleAdd = (e: any) => {
    const addInternal = async () => {
      try {
        var request = new CreateTeamMemberRequest({
          activeDirectoryId: this.state.activeDirectoryUser?.id!,
          email: this.state.activeDirectoryUser?.email!,
          name: this.state.activeDirectoryUser?.fullName!
        } as ICreateTeamMemberRequest);
        await teamMembersClient.create(request);
        toast.success("Team member '" + this.state.activeDirectoryUser?.fullName! + "' added");
        this.props.onSubmitted();
      } catch (error) {
        if (error.response.status === 400) {
          toast.error(error.response.data.title);
        } else {
          toast.error("Uh-oh! Something went wrong while trying to add the team member.");
        }
      }
    };

    e.preventDefault();

    this.tracker.track(addInternal());
  };

  private handleCancel = () => {
    this.props.onCancelled();
  };

  private isValid = () => {
    return !!this.state.activeDirectoryUser;
  };
}
