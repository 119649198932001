import {AchievementModel} from "./api";
import {axiosClient} from "./apiClients";

export const achievementsGivenClient = {
  list: async function (): Promise<AchievementModel[]> {
    const url = "/api/AchievementsGiven";
    const headers = {
      "Accept": "application/json"
    };

    const response = await axiosClient.get<AchievementModel[]>(url, {
      headers
    });

    if (response.status !== 200 && response.status != 204) {
      throw "Unexpected server error, please try again";
    }

    return response.data;
  }
};