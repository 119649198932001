import * as React from "react";
import ListView from "../ListView";
import AdminPurchase from "./AdminPurchase";
import { purchasesClient, IPurchaseModel } from "../http";

export default class ManagePurchases extends React.Component {
  public render() {
    return <ListView heading="Manage Purchases" 
                     loadItems={this.getPurchases} 
                     renderItem={this.renderPurchase} 
                     noItemsMessage="It doesn't look like anyone has purchased anything yet." />;
  }

  private getPurchases = async () => {
    return await purchasesClient.listAll();
  };

  private renderPurchase = (purchase: IPurchaseModel, refreshItems: () => void) => {
    return <AdminPurchase purchase={purchase} 
                          onProcessed={refreshItems}
                          onDeleted={refreshItems}/>;
  };
}
