import * as React from "react";
import styled from "styled-components";

export interface ITextboxProps {
  width?: string;
  type?: "text" | "password" | "number";
  placeholder?: string;
  multiline?: boolean;
  rows?: number;
  onChange?: (e: any) => void;
  value?: any;
  disabled?: boolean;
}

const defaultProps = {
  type: "text",
  width: "100%"
} as ITextboxProps;

export default class Textbox extends React.Component<ITextboxProps, any> {
  constructor(props: ITextboxProps = defaultProps) {
    super(props);
  }

  public render() {
    return this.props.multiline ? (
      <TextArea placeholder={this.props.placeholder} rows={this.props.rows || 5} value={this.props.value} onChange={this.props.onChange} disabled={this.props.disabled || false}/>
    ) : (
      <Input type={this.props.type} placeholder={this.props.placeholder} value={this.props.value} onChange={this.props.onChange} width={this.props.width} min="1" disabled={this.props.disabled || false} />
    );
  }
}

const Input = styled.input`
  font-size: 16px;
  border: 0;
  outline: none;
  border-bottom: ${props => "1px solid " + props.theme.secondaryColor};
  width: ${props => props.width};
  padding: 8px;
  padding-bottom: 9px;
  position: relative;
  top: -1px;
  border-radius: 0;

  &:active,
  &:focus {
    border-bottom-width: 2px;
    padding-bottom: 8px;
  }

  @media (max-width: 767px) {
    padding: 3px;
    padding-bottom: 5px;

    &:active,
    &:focus {
      padding-bottom: 4px;
    }
  }
`;
const TextArea = styled.textarea`
  font-size: 16px;
  border: 0;
  outline: none;
  border-bottom: ${props => "1px solid " + props.theme.secondaryColor};
  padding: 8px;
  padding-bottom: 9px;
  width: 100%;
  resize: none;

  &:active,
  &:focus {
    border-bottom-width: 2px;
    padding-bottom: 8px;
  }

  @media (max-width: 767px) {
    padding: 3px;
    padding-bottom: 5px;

    &:active,
    &:focus {
      padding-bottom: 4px;
    }
  }
`;
