import * as React from "react";
import { toast } from "react-toastify";
import Button from "../Button";
import ButtonToolbar from "../ButtonToolbar";
import Form from "../Forms/Form";
import FormGroup from "../Forms/FormGroup";
import Textbox from "../Forms/Textbox";
import Loading from "../Loading";
import { LoadingTracker } from "../LoadingTracker";
import {
  UpdateTeamMemberRequest,
  ITeamMemberModel, teamMembersClient,
} from "../http";

export interface ITeamMemberFormProps {
  onSubmitted: () => void;
  onCancelled: () => void;
  initialState?: ITeamMemberModel;
}

interface ITeamMemberFormState extends ITeamMemberModel {
  teamMembers?: ITeamMemberModel[];
}

export default class EditTeamMemberForm extends React.Component<ITeamMemberFormProps, ITeamMemberFormState> {
  private readonly tracker: LoadingTracker;
  constructor(props: ITeamMemberFormProps) {
    super(props);

    const initialState: ITeamMemberModel = {
      teamMemberId: "",
      name: "",
      email: "",
      availablePoints: 0,
      availablePointsToAllocate: 0,
      isDeleted: false,
    };

    this.state = {
      ...(props.initialState || initialState)
    };

    this.tracker = new LoadingTracker();
  }

  public componentDidMount() {
    const loadTeamMembers = async () => {
      try {
        const teamMembers = await teamMembersClient.list();
        this.setState({
          teamMembers: teamMembers
        });
        window.scrollTo(0, 0);
      } catch {
        toast.error("Uh-oh! An error occurred while loading available team members");
      }
    };

    this.tracker.track(loadTeamMembers());
  }

  public render() {
    return (
      <Loading tracker={this.tracker}>
        <Form onSubmit={this.handleEdit}>
          <FormGroup>
            <label>Name:</label><br/>
            <Textbox placeholder="Name" width="300px" onChange={this.handleFormFieldChange("name")} value={this.state.name} disabled={true} />
          </FormGroup>
          <FormGroup>
            <label>Email:</label><br/>
            <Textbox placeholder="Email" width="300px" onChange={this.handleFormFieldChange("email")} value={this.state.email} disabled={true} />
          </FormGroup>
          <FormGroup>
            <label>Available points to spend:</label><br/>
            <Textbox placeholder="Available points to spend" width="300px" onChange={this.handleFormFieldChange("availablePoints")} value={this.state.availablePoints} disabled={true} />
          </FormGroup>
          <FormGroup>
            <label>Points left to allocate:</label><br/>
            <Textbox placeholder="Points left to allocate" width="300px" onChange={this.handleFormFieldChange("availablePointsToAllocate")} value={this.state.availablePointsToAllocate} />
          </FormGroup>
          <ButtonToolbar>
            <Button onClick={this.handleCancel} variant="secondary">
              Cancel
            </Button>
            <Button type="submit" disabled={!this.isValid()}>
              Save TeamMember
            </Button>
          </ButtonToolbar>
        </Form>
      </Loading>
    );
  }

  private handleFormFieldChange = (fieldName: string) => (e: any) => {
    this.setState({ [fieldName]: e.target.value } as ITeamMemberFormState);
  };

  private handleEdit = (e: any) => {
    const editInternal = async () => {
      try {
        const request = new UpdateTeamMemberRequest({
          availablePoints: this.state.availablePoints!,
          availablePointsToAllocate: this.state.availablePointsToAllocate!,
        });
        await teamMembersClient.update(this.state.teamMemberId!, request);
        toast.success("Team Member '" + this.state.name + "' saved");
        this.props.onSubmitted();
      } catch (error) {
        if (error.response.status === 400) {
          toast.error(error.response.data.title);
        } else {
          toast.error("Uh-oh! Something went wrong while trying to save the team member.");
        }
      }
    };

    e.preventDefault();
    this.tracker.track(editInternal());
  };

  private handleDelete = (e: any) => {
    const deleteInternal = async () => {
      try {
        await teamMembersClient.delete(this.state.teamMemberId!);
        toast.success("Team Member '" + this.state.name + "' deleted");
        this.props.onSubmitted();
      } catch (error) {
        if (error.response.status === 400) {
          toast.error(error.response.data.title);
        } else {
          toast.error("Uh-oh! Something went wrong while trying to delete the team member.");
        }
      }
    };

    e.preventDefault();
    this.tracker.track(deleteInternal());
  };

  private handleCancel = () => {
    this.props.onCancelled();
  };

  private isValid = () => {
    return this.state.name && this.state.email && (this.state.availablePoints ?? 0) >= 0 && (this.state.availablePointsToAllocate ?? 0) >= 0 && this.state.teamMemberId;
  };
}
