import * as React from "react";
import SwipeableViews from "react-swipeable-views";
import { toast } from "react-toastify";
import styled from "styled-components";
import Button from "./Button";
import Loading from "./Loading";
import { LoadingTracker } from "./LoadingTracker";

interface IListViewProps<T,Y> {
  heading?: string;
  loadItems: () => Promise<T[]>;
  noItemsMessage?: string;
  renderItem: (item: T, refreshItems: () => void, editItem: (item: T) => void, deleteItem: (item: T) => void) => React.ReactNode;
  addForm?: (onItemSaved: () => void, onAddCancelled: () => void) => React.ReactNode;
  addText?: string;
  editForm?: (item: T, onItemSaved: () => void, onEditCancelled: () => void) => React.ReactNode;
  editText?: string;
  deleteForm?: (item: T, onItemSaved: () => void, onDeleteCancelled: () => void) => React.ReactNode;
  deleteText?: string;
  renderFilter?: (items: Y[], onChange: (e:any) => void, value: string) => React.ReactNode;
  filterItems?:  (items: T[], id: any) => T[];
  loadFilterItems?: () => Promise<Y[]>
}

interface IListViewState<T,Y> {
  activeTab: number;
  items: T[] | null;
  unfilteredItems: T[] | null;
  isAdding: boolean;
  isEditing: boolean;
  isDeleting: boolean;
  activeItem: T | null;
  filterValue: any | undefined;
  filterItems: Y[]
}

export default class ListView<T,Y> extends React.Component<IListViewProps<T,Y>, IListViewState<T,Y>> {
  private loadingTracker: LoadingTracker;

  constructor(props: any) {
    super(props);

    this.state = {
      activeItem: null,
      activeTab: 0,
      isAdding: false,
      isEditing: false,
      isDeleting: false,
      items: null,
      filterValue: undefined,
      filterItems: [],
      unfilteredItems: null
    };

    this.loadingTracker = new LoadingTracker();
  }

  public componentDidMount() {
    this.loadItems();
    this.loadFilterItems();
  }

  public render() {
    return (
      <div>
        <SwipeableViews index={this.state.activeTab} disabled={true} slideStyle={{ minHeight: "125px" }}>
          <div>
            <ListHeadingContainer>
              {this.props.heading && <ListHeading>{this.props.heading}</ListHeading>}
              {this.props.addForm && <Button onClick={this.showForm("add", null)}>{this.props.addText || "Add"}</Button>}
            </ListHeadingContainer>
            {this.state.filterItems && this.props.renderFilter && this.props.renderFilter(this.state.filterItems, this.handleFilterChange, this.state.filterValue)}
            <Loading tracker={this.loadingTracker}>
              {this.state.items && this.state.items.map(item => this.props.renderItem(item, this.loadItems, this.showForm("edit", item), this.showForm("delete", item)))}

              {this.state.items && this.state.items.length === 0 && <span>{this.props.noItemsMessage}</span>}
            </Loading>
          </div>
          <div>
            {this.state.activeTab === 1 && this.state.isAdding && this.renderAddForm()}
            {this.state.activeTab === 1 && this.state.isEditing && this.renderEditForm()}
            {this.state.activeTab === 1 && this.state.isDeleting && this.renderDeleteForm()}
          </div>
        </SwipeableViews>
      </div>
    );
  }

  private loadItems = () => {
    this.loadingTracker.track(
      this.props
        .loadItems()
        .then(items => {
          this.setState({ items: items, unfilteredItems: items });
        })
        .catch(() => {
          toast.error("Uh-oh! Something went wrong while loading items.");
        })
    );
  };

  private loadFilterItems = () => {
    if(this.props.loadFilterItems) {
    this.loadingTracker.track(
      this.props
        .loadFilterItems()
        .then(filterItems => {
          this.setState({ filterItems });
        })
        .catch(() => {
          toast.error("Uh-oh! Something went wrong while loading items.");
        })
    );
  }
  };

  private handleFilterChange = (e: any) => {
    this.setState({ filterValue: e.target.value });
    if(this.props.filterItems) {
      this.setState({items : this.props.filterItems(this.state.unfilteredItems ?? [], e.target.value)});
    }
  };

  private onItemSaved = () => {
    this.loadItems();
    this.hideForm();
  };

  private onCancelled = () => {
    this.hideForm();
  };

  private showForm = (mode: "add" | "edit" | "delete", item: T | null) => () => {
    this.setState({
      activeItem: item,
      activeTab: 1,
      isAdding: mode === "add",
      isEditing: mode === "edit",
      isDeleting: mode === "delete",
    });
  };

  private hideForm = () => {
    this.setState({ activeTab: 0 });
  };

  private renderAddForm = () => {
    return (
      <div>
        <ListHeadingContainer>
          <h2>{this.props.addText || "Add"}</h2>
        </ListHeadingContainer>
        {this.props.addForm && this.props.addForm(this.onItemSaved, this.onCancelled)}
      </div>
    );
  };

  private renderEditForm = () => {
    return (
      <div>
        <ListHeadingContainer>
          <h2>{this.props.editText || "Edit"}</h2>
        </ListHeadingContainer>
        {this.props.editForm && this.state.activeItem && this.props.editForm(this.state.activeItem, this.onItemSaved, this.onCancelled)}
      </div>
    );
  };

  private renderDeleteForm = () => {
    return (
        <div>
          <ListHeadingContainer>
            <h2>{this.props.deleteText || "Delete"}</h2>
          </ListHeadingContainer>
          {this.props.deleteForm && this.state.activeItem && this.props.deleteForm(this.state.activeItem, this.onItemSaved, this.onCancelled)}
        </div>
    );
  };
}

const ListHeadingContainer = styled.div`
  margin-bottom: 10px;
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: auto;
  align-items: center;
`;

const ListHeading = styled.h2`
  grid-row: 1;
  grid-column: 1;
  @media (max-width: 767px) {
    font-size: 16px;
  }
`;
