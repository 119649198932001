import {axiosClient} from "./apiClients";

export async function getPurchasesByFinancialYear(financialYearEnding: number): Promise<string> {
  const response = await axiosClient.get(`/api/Reports/PurchasesByFinancialYear/${financialYearEnding}`);

  if (response.status !== 200 && response.status != 204) {
    throw "Unexpected server error, please try again";
  }
  
  return response.data;
}

export async function getSpendByFinancialYear(financialYearEnding: number): Promise<string> {
  const response = await axiosClient.get(`/api/Reports/SpendByFinancialYear/${financialYearEnding}`);

  if (response.status !== 200 && response.status != 204) {
    throw "Unexpected server error, please try again";
  }
  
  return response.data;
}