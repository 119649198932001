import * as React from "react";
import { toast } from "react-toastify";
import Button from "../Button";
import ButtonToolbar from "../ButtonToolbar";
import Form from "../Forms/Form";
import FormGroup from "../Forms/FormGroup";
import Textbox from "../Forms/Textbox";
import Loading from "../Loading";
import { LoadingTracker } from "../LoadingTracker";
import { IRewardModel } from "../http";
import { rewardsClient } from "../http";

export interface IRewardFormProps {
  onSubmitted: () => void;
  onCancelled: () => void;
  initialState?: IRewardModel;
}

export interface IRewardFormFields {
  description: string;
  name: string;
  points: number | string | undefined;
  rewardId?: string;
  image?: File;
  showMessageBox: boolean;
}

interface IRewardFormState extends IRewardFormFields {
  mode: "add" | "edit";
}

const defaultFormState = { name: "", description: "", points: 0, image: undefined };

export default class RewardForm extends React.Component<IRewardFormProps, IRewardFormState> {
  private tracker: LoadingTracker;
  constructor(props: IRewardFormProps) {
    super(props);

    const initialState: IRewardFormFields = {
      description: props.initialState && props.initialState.description ? props.initialState.description : "",
      name: props.initialState && props.initialState.name ? props.initialState.name : "",
      points: props.initialState && props.initialState.points ? props.initialState.points : 0,
      rewardId: props.initialState ? props.initialState.rewardId : undefined,
      showMessageBox: props.initialState ? props.initialState.showMessageBox : false
    };

    this.state = {
      mode: props.initialState ? "edit" : "add",
      ...initialState
    };

    this.tracker = new LoadingTracker();
  }

  public render() {
    return (
      <Loading tracker={this.tracker}>
        <Form onSubmit={this.state.mode === "add" ? this.handleCreate : this.handleEdit}>
          <FormGroup>
            <Textbox placeholder="Name *" onChange={this.handleFormFieldChange("name")} value={this.state.name} />
          </FormGroup>
          <FormGroup>
            <Textbox multiline={true} placeholder="Description *" onChange={this.handleFormFieldChange("description")} value={this.state.description} />
          </FormGroup>
          <FormGroup>
            <Textbox type="number" 
                     placeholder="Points *" 
                     onChange={this.handleFormFieldChange("points")} 
                     value={this.state.points} />
          </FormGroup>
          <FormGroup>
            <label>
              <input type="checkbox"
                     onChange={this.toggleCheckbox}
                     checked={this.state.showMessageBox}/>
              &nbsp;Show message box
            </label>
          </FormGroup>
          <FormGroup>
            <input type="file" onChange={this.handleFileSelected} />
          </FormGroup>
          <ButtonToolbar>
            <Button onClick={this.handleCancel} variant="secondary">
              Cancel
            </Button>
            {this.state.mode === "add" && (
              <Button type="submit" disabled={!this.isValid()}>
                Add Reward
              </Button>
            )}
            {this.state.mode === "edit" && (
              <Button type="submit" disabled={!this.isValid()}>
                Save Reward
              </Button>
            )}
          </ButtonToolbar>
        </Form>
      </Loading>
    );
  }

  private toggleCheckbox = () => {
    this.setState({showMessageBox: !this.state.showMessageBox});
  }

  private handleFormFieldChange = (fieldName: string) => (e: any) => {
    this.setState({ [fieldName]: e.target.value } as IRewardFormState);
  };

  private handleFileSelected = (e: any) => {
    this.setState({ image: e.target.files[0] });
  };

  private handleCreate = async (e: any) => {
    e.preventDefault();

    const handleCreateInternal = async () => {
      try {
        await rewardsClient.create(
          this.state.name, 
          this.state.description, 
          this.state.points as number, 
          this.state.showMessageBox,
          { fileName: this.state.image!.name, data: this.state.image });

        toast.success("Reward '" + this.state.name + "' added");
        this.setState(defaultFormState);
        this.props.onSubmitted();
      } catch {
        toast.error("Uh-oh! An error occurred while adding the reward");
      }
    };

    this.tracker.track(handleCreateInternal());
  };

  private handleEdit = (e: any) => {
    e.preventDefault();

    const handleUpdateInternal = async () => {
      try {
        await rewardsClient.update(
          this.state.rewardId!, 
          this.state.name, 
          this.state.description, 
          this.state.points as number, 
          this.state.showMessageBox,
          this.state.image ? 
          { 
            fileName: this.state.image!.name, 
            data: this.state.image 
          } : null);

        toast.success("Reward '" + this.state.name + "' saved");
        this.setState(defaultFormState);
        this.props.onSubmitted();
      } catch {
        toast.error("Uh-oh! An error occurred while saving the reward");
      }
    };

    this.tracker.track(handleUpdateInternal());
  };

  private handleCancel = () => {
    this.props.onCancelled();
  };

  private isValid = () => {
    return this.state.name 
      && this.state.description 
      && this.state.points 
      && this.state.points >= 0 
      && (this.state.mode == "edit" || this.state.image);
  };
}
