import React, {useState} from 'react';
import Textbox from "../Forms/Textbox";
import ButtonToolbar from "../ButtonToolbar";
import Button from "../Button";
import {toast} from "react-toastify";
import {getPurchasesByFinancialYear, getSpendByFinancialYear} from "../http/ReportsClient";
import FormGroup from "../Forms/FormGroup";
import Form from "../Forms/Form";
import styled from 'styled-components';

enum ReportType {
  Purchases,
  Spend
} 

async function downloadPurchasesByFinancialYearCsv(yearEnding: number, reportType: ReportType) {
  try {
    let csv: string = "";
    let downloadName = `${yearEnding}.csv`;

    switch(reportType) {
      case ReportType.Purchases:
        csv = await getPurchasesByFinancialYear(yearEnding);
        downloadName = `purchases-by-fy-ending-${yearEnding}.csv`;
        break;
      case ReportType.Spend:
        csv = await getSpendByFinancialYear(yearEnding);
        downloadName = `spend-by-fy-ending-${yearEnding}.csv`;
        break;
    }
    
    const blob = new Blob([csv], {
      type: 'text/csv'
    });
    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', downloadName);
    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  } catch (error) {
    console.log(error);
    toast.error("Uh-oh! Something went wrong while trying to generate the report.");    
  }
}

export const Reports: React.FC = () => {
  const [purchasesByFinancialYearEnding, setPurchasesByFinancialYearEnding] = useState(new Date().getFullYear());
  const [spendByFinancialYearEnding, setSpendByFinancialYearEnding] = useState(new Date().getFullYear());

  return (
    <>
      <h1>Reports</h1>

      <ReportContainer>
        <h2>Purchases by financial year</h2>
        <FormGroup>
          <label>
            Financial year ending:<br/>
            <Textbox type="number"
                     onChange={e => setPurchasesByFinancialYearEnding(parseInt(e.target.value))}
                     value={purchasesByFinancialYearEnding}/>
          </label>
        </FormGroup>
        <ButtonToolbar>
          <Button onClick={() => downloadPurchasesByFinancialYearCsv(purchasesByFinancialYearEnding, ReportType.Purchases)}>Generate report</Button>
        </ButtonToolbar>
      </ReportContainer>      
      <ReportContainer>
        <h2>Spend by financial year</h2>
        <FormGroup>
          <label>
            Financial year ending:<br/>
            <Textbox type="number"
                     onChange={e => setSpendByFinancialYearEnding(parseInt(e.target.value))}
                     value={spendByFinancialYearEnding}/>
          </label>
        </FormGroup>
        <ButtonToolbar>
          <Button onClick={() => downloadPurchasesByFinancialYearCsv(spendByFinancialYearEnding, ReportType.Spend)}>Generate report</Button>
        </ButtonToolbar>
      </ReportContainer>      
    </>
  );
};

const ReportContainer = styled.div`
  margin-bottom: 20px;
  margin-top: 20px;
`;