import { createGlobalStyle } from "styled-components";

export interface ITheme {
  dangerColor: string;
  primaryColor: string;
  secondaryColor: string;
  tertiaryColor: string;
  fontFamily: string;
  headerHeight: number;
}

const Theme = {
  dangerColor: "#dc3545",
  fontFamily: 'Raleway, "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif',
  headerHeight: 50,
  primaryColor: "#343538",
  secondaryColor: "#02cc44",
  tertiaryColor: "#ffffff"
} as ITheme;

export default Theme;

export const GlobalStyle = createGlobalStyle`
    *, body, html {
        font-family: ${Theme.fontFamily};
        color: ${Theme.primaryColor};
        box-sizing: border-box;
        padding: 0;
        margin: 0;
    }

  /* Styling of overlay */
  .bm-overlay {
    @media (min-width: 768px){
      display: none;
    }

    background: rgba(0, 0, 0, 0.3);
    top: 0;
  }
`;
