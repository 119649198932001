import * as React from "react";
import { toast } from "react-toastify";
import Button from "../Button";
import ButtonToolbar from "../ButtonToolbar";
import Form from "../Forms/Form";
import FormGroup from "../Forms/FormGroup";
import Select from "../Forms/Select";
import Textbox from "../Forms/Textbox";
import Loading from "../Loading";
import { LoadingTracker } from "../LoadingTracker";
import { IAchievementModel, ITeamMemberModel, teamMembersClient, achievementsClient, CreateAchievementRequest, ICreateAchievementRequest } from "../http";

export interface IAchievementFormProps {
  profile: ITeamMemberModel | undefined;
  onSubmitted: () => void;
  onCancelled: () => void;
  initialState?: IAchievementModel;
}

interface IAchievementFormState extends IAchievementModel {
  teamMembers?: ITeamMemberModel[];
}

export default class AchievementForm extends React.Component<IAchievementFormProps, IAchievementFormState> {
  private tracker: LoadingTracker;
  constructor(props: IAchievementFormProps) {
    super(props);

    const initialState: IAchievementModel = {
      teamMemberId: "",
      achievementId: "",
      name: "",
      description: "",
      achievedAtUtc: undefined,
      pointsEarned: 0
    };

    this.state = {
      ...(props.initialState || initialState)
    };

    this.tracker = new LoadingTracker();
  }

  public componentDidMount() {
    const loadTeamMembers = async () => {
      try {
        var teamMembers = await teamMembersClient.list();
        this.setState({
          teamMembers: teamMembers.filter(t => t.email !== this.props.profile?.email)
        });

        if (!this.state.teamMemberId) {
          this.setState({ teamMemberId: teamMembers[0].teamMemberId });
        }
      } catch {
        toast.error("Uh-oh! An error occurred while loading available team members");
      }
    };

    this.tracker.track(loadTeamMembers());
  }

  public render() {
    return (
      <Loading tracker={this.tracker}>
        <Form onSubmit={this.handleAdd}>
          <FormGroup>
            <label>
              Recipient *<br/>
              <Select placeholder="Team Member *" onChange={this.handleFormFieldChange("teamMemberId")} value={this.state.teamMemberId}>
                {this.state.teamMemberId === undefined && <option>Team Member *</option>}
                {this.state.teamMembers &&
                  this.state.teamMembers.map(t => (
                    <option key={t.teamMemberId} value={t.teamMemberId}>
                      {t.name}
                    </option>
                  ))}
              </Select>
            </label>
          </FormGroup>
          <FormGroup>
            <label>
              Award name *<br/>
              <Textbox placeholder="Award name *" onChange={this.handleFormFieldChange("name")} value={this.state.name} />
            </label>            
          </FormGroup>
          <FormGroup>
            <label>
              Description *<br/>
              <Textbox multiline={true} placeholder="Description *" onChange={this.handleFormFieldChange("description")} value={this.state.description} />
            </label>
          </FormGroup>
          <FormGroup>
            <label>
              Points earned *<br/>
              <Textbox type="number" placeholder="Points Earned *" onChange={this.handleFormFieldChange("pointsEarned")} value={this.state.pointsEarned} />
            </label>
          </FormGroup>
          <ButtonToolbar>
            <Button onClick={this.handleCancel} variant="secondary">
              Cancel
            </Button>
            <Button type="submit" disabled={!this.isValid()}>
              Award Achievement
            </Button>
          </ButtonToolbar>
        </Form>
      </Loading>
    );
  }

  private handleFormFieldChange = (fieldName: string) => (e: any) => {
    this.setState({ [fieldName]: e.target.value } as IAchievementFormState);
  };

  private handleAdd = (e: any) => {
    const addInternal = async () => {
      try {
        var request = new CreateAchievementRequest({
          description: this.state.description!,
          name: this.state.name!,
          pointsEarned: +this.state.pointsEarned!,
          teamMemberId: this.state.teamMemberId!,
          awardedByTeamMemberId: this.props.profile?.teamMemberId,
        } as ICreateAchievementRequest);
        await achievementsClient.create(request);
        toast.success("Achievement '" + this.state.name + "' added");
        this.props.onSubmitted();
      } catch (error) {
        if (error.response.status === 400) {
          toast.error(error.response.data.title);
        } else {
          toast.error("Uh-oh! Something went wrong while trying to add the achievement.");
        }
      }
    };

    e.preventDefault();

    this.tracker.track(addInternal());
  };

  private handleCancel = () => {
    this.props.onCancelled();
  };

  private isValid = () => {
    return this.state.name && this.state.description && this.state.pointsEarned && this.state.pointsEarned >= 0 && this.state.teamMemberId;
  };
}
