import moment from "moment";
import * as React from "react";
import { toast } from "react-toastify";
import styled from "styled-components";
import Button from "../Button";
import Card from "../Card";
import Loading from "../Loading";
import { LoadingTracker } from "../LoadingTracker";
import { IPurchaseModel, purchasesClient } from "../http";
import ButtonToolbar from "../ButtonToolbar";

export interface IAdminPurchaseProps {
  purchase: IPurchaseModel;
  onProcessed: () => void;
  onDeleted: () => void;
}

export default class AdminPurchase extends React.Component<IAdminPurchaseProps, any> {
  private loadingTracker: LoadingTracker;

  constructor(props: any) {
    super(props);

    this.loadingTracker = new LoadingTracker();
  }

  public render() {
    return (
      <Loading tracker={this.loadingTracker}>
        <Card>
          <Purchase>
            <PurchasedBy>{this.props.purchase.teamMemberName}</PurchasedBy>
            <RewardName>
              {this.props.purchase.quantity} x {this.props.purchase.rewardName}
            </RewardName>
            <PurchasedAt
              title={moment
                .utc(this.props.purchase.purchasedAtUtc)
                .local()
                .format("MMMM Do YYYY, h:mm:ss a")}
            >
              {moment.utc(this.props.purchase.purchasedAtUtc).fromNow()}
            </PurchasedAt>
            <RewardDescription>{this.props.purchase.rewardDescription}</RewardDescription>
            <RewardPoints>{this.props.purchase.points} points</RewardPoints>
            <RewardMessage>
              {this.props.purchase.message && (
                <span><strong>Message:</strong> {this.props.purchase.message}</span>
              )}
              {!this.props.purchase.message && (
                <span>No message</span>
              )}
            </RewardMessage>
            <Actions>
              <ButtonToolbar>
                {!this.props.purchase.isProcessed && <Button onClick={this.handleProcessClick(this.props.purchase)}>Mark as processed</Button>}
                {this.props.purchase.isProcessed && <ProcessedAt>{"Processed " + moment.parseZone(this.props.purchase.processedAtUtc).fromNow()}</ProcessedAt>}
                <Button onClick={() => this.deletePurchase()}
                        variant="danger">
                  Delete
                </Button>        
              </ButtonToolbar>
            </Actions>
          </Purchase>
        </Card>
      </Loading>
    );
  }
  
  private deletePurchase() {
    this.loadingTracker.track(
      purchasesClient
        .delete(this.props.purchase.purchaseId!)
        .then(() => {
          this.props.onDeleted();
          toast.success(`Purchase '${this.props.purchase.rewardName}' deleted`);
        })
        .catch(() => {
          toast.error("Uh-oh! Something went wrong while trying to delete the purchase.");
        })
    );
  }

  private handleProcessClick = (purchase: IPurchaseModel) => () => {
    this.loadingTracker.track(
      purchasesClient
        .markAsProcessed(purchase.purchaseId!)
        .then(() => {
          this.props.onProcessed();
          toast.success("Marked purchase as processed");
        })
        .catch(() => {
          toast.error("Uh-oh! Something went wrong while marking purchase as processed");
        })
    );
  };
}

const Purchase = styled.div`
  display: grid;
  grid-template-rows: auto auto auto auto;
  grid-template-columns: min-content auto 1fr;
  grid-row-gap: 5px;
`;

const RewardName = styled.h3`
  grid-column: 1;
  grid-row: 2;
  white-space: nowrap;
`;

const RewardDescription = styled.div`
  grid-column: 1 / 4;
  grid-row: 3;
`;

const RewardPoints = styled.div`
  align-self: end;
  grid-column: 2;
  grid-row: 2;
  margin-left: 10px;
`;

const RewardMessage = styled.div`
  align-self: end;
  grid-column: 3;
  grid-row: 2;
  margin-left: 10px;
`;

const PurchasedAt = styled.small`
  justify-self: end;
  align-self: center;
  grid-column: 3;
  grid-row: 1;
  white-space: nowrap;
`;

const PurchasedBy = styled.span`
  grid-column: 1 / 3;
  grid-row: 1;
`;

const Actions = styled.div`
  margin-top: 10px;
  grid-column: 1/4;
  grid-row: 4;
`;
const ProcessedAt = styled.small``;
