import { runWithAdal } from "react-adal";
import { authContext } from "./ActiveDirectory";

const DO_NOT_LOGIN = false;

const theWindow = (window as any);

fetch("/settings.json")
  .then((response: any) => {
    return response.json();
  })
  .then(json => {
    theWindow.appSettings = json;

    runWithAdal(
      authContext,
      () => {
        require("./IndexApp.tsx");
      },
      DO_NOT_LOGIN
    );
  });


