import { adalFetch, AuthenticationContext, withAdalLogin } from "react-adal";

export const adalConfig = {
  cacheLocation: "localStorage",
  clientId: "3cffa274-6eda-471c-9c30-56c3b72769dc",
  endpoints: {
    api: "https://sixpivot.com.au/2dac8f15-3bcd-4f48-b3a8-0f5c2b717270"
  },
  tenant: "51b792d5-bfd3-4dbd-82d2-f42aef2fa7ee"
};

export const authContext = new AuthenticationContext(adalConfig);

export const adalApiFetch = (fetch: any, url: any, options: any) =>
  adalFetch(authContext, adalConfig.endpoints.api, fetch, url, options);

export const withAdalLoginApi = withAdalLogin(
  authContext,
  adalConfig.endpoints.api
);

export const getToken = () => {
  return authContext.getCachedToken(authContext.config.clientId);
};

type IUser = {
  oid: string;
  upn: string;
  roles: string[];
}

export const getUser = () => {
  return (authContext as any)._extractIdToken(authContext.getCachedToken(authContext.config.clientId)) as IUser;
}

export const isUserInRole = (role: string) => {
  return getUser().roles && getUser().roles.some(r => r === role);
}
