import React from "react";
import {ITeamMemberModel} from "../http";

interface IPointsAvailableMessageProps {
  profile: ITeamMemberModel;
}

export const PointsAvailableMessage: React.FC<IPointsAvailableMessageProps> = (props: IPointsAvailableMessageProps) => {
  const { profile } = props;
  
  if (profile === null) {
    return <p>Loading...</p>;
  }
  
  console.log("profile", profile)
  
  return (
    <p>
      You have <strong>{profile.availablePoints}</strong> points available to spend,
      and <strong>{profile.availablePointsToAllocate}</strong> points left to allocate.
    </p>
  );
};